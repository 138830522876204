import {Button, Input, toast} from '@cashiaApp/web-components';
import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import EyeSlash from './../../assets/icons/eye-slash.svg';
import Eye from './../../assets/icons/eye.svg';
import {signIn, SignInData, signUp, SignUpData} from './api';
import Info from '../../assets/icons/info-circle.svg';
import Lock from '../../assets/icons/lock_p.svg';
import Send from '../../assets/icons/send_p.svg';
import SignupBgImage from '../../assets/images/Paymentl_bg_signup.svg';
import LogoSvg from '../../assets/logos/cashia_logo_pink.svg';
import CustomPhoneInput from '../../components/common/CustomPhoneInput';
import Spinner from '../../components/tailwind/Spinner';
import {useAuth} from '../../utils/auth';
import validateKenyanPhoneNumber from '../../utils/validatePhoneNumber';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: {
    countryCode: string;
    number: string;
  };
  password: string;
}
interface FormErrors {
  [key: string]: string;
}

type ValidateFunction = (values: FormValues, isSignUp: boolean) => FormErrors;

const useFormValidation = (
  initialState: FormValues,
  validate: ValidateFunction,
  isSignUp: boolean
) => {
  const [values, setValues] = useState<FormValues>(initialState);
  const [errors, setErrors] = useState<FormErrors>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const reset = useCallback(() => {
    setValues(initialState);
    setErrors({});
    setIsSubmitting(false);
  }, [initialState]);

  return {
    handleChange,
    reset,
    values,
    setValues,
    errors,
    setErrors,
    isSubmitting,
    validate,
    isSignUp,
  };
};

const initialState: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: {
    countryCode: '',
    number: '',
  },
  password: '',
};

const validate: ValidateFunction = (values, isSignUp) => {
  const errors: FormErrors = {};

  if (isSignUp) {
    if (!values.firstName.trim()) {
      errors.firstName = 'First name is required';
    }
    if (!values.lastName.trim()) {
      errors.lastName = 'Last name is required';
    }
    if (!values?.phoneNumber?.countryCode?.trim()) {
      errors.phoneNumber = 'Country code is required';
    }
    if (!values.phoneNumber.number.trim()) {
      errors.phoneNumber = 'Phone number is required';
    } else if (
      !validateKenyanPhoneNumber(values?.phoneNumber?.number?.trim() || '')
    ) {
      errors.phoneNumber = 'Phone number is invalid';
    }
  }

  if (!values.email) {
    errors.email = 'Email is required';
  } else {
    const emailRegex =
      /^[a-zA-Z0-9]+(?:[._-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:[._-][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(values.email)) {
      errors.email = 'Email address is invalid';
    }
  }

  if (!values.password) {
    errors.password = 'Password is required';
  } else if (isSignUp) {
    const passwordErrors = [];

    if (values.password.length < 8) {
      passwordErrors.push('Password must be at least 8 characters');
    }

    if (values.password.length > 64) {
      passwordErrors.push('Your password must not exceed 64 characters');
    }

    if (!/[a-z]/.test(values.password)) {
      passwordErrors.push(
        'Password must contain at least one lowercase letter'
      );
    }

    if (values.password.includes(' ')) {
      passwordErrors.push('Password must not contain spaces');
    }

    if (!/[A-Z]/.test(values.password)) {
      passwordErrors.push(
        'Password must contain at least one uppercase letter'
      );
    }

    if (!/\d/.test(values.password)) {
      passwordErrors.push(
        'Password must contain at least one numeric character'
      );
    }

    if (!/[^a-zA-Z0-9]/.test(values.password)) {
      passwordErrors.push(
        'Password must contain at least one special character'
      );
    }

    if (passwordErrors.length > 0) {
      errors.password = passwordErrors.join(', ');
    }
  }

  return errors;
};

const AuthComponent: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {login, isAuthenticated} = useAuth();
  const [isSignUp, setIsSignUp] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false);

  const {handleChange, values, setValues, errors, setErrors} =
    useFormValidation(initialState, validate, isSignUp);

  useEffect(() => {
    const pathname = location.pathname;
    setIsSignUp(pathname === '/signup');
  }, [location.pathname]);

  const toggleAuthMode = () => {
    setIsNavigating(true);
    navigate(isSignUp ? '/login' : '/signup');
  };

  useEffect(() => {
    if (isNavigating) {
      const timer = setTimeout(() => setIsNavigating(false), 500);
      return () => clearTimeout(timer);
    }
  }, [isNavigating]);

  const toastShown = useRef(false);

  useEffect(() => {
    if (isAuthenticated && !toastShown.current) {
      toast.success('Login successful!');
      toastShown.current = true;
      navigate('/home');
    }
  }, [isAuthenticated, navigate]);

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    setIsSubmitting(true);
    const validationErrors = validate(values, isSignUp);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        if (isSignUp) {
          const signUpData: SignUpData = {
            email: values.email,
            first_name: values.firstName,
            last_name: values.lastName,
            password: values.password,
            phone: {
              country_code: values.phoneNumber.countryCode,
              number: values.phoneNumber.number,
            },
          };
          await signUp(signUpData);
          toast.success('Signup successful! Please log in.');
          navigate('/login');
        } else {
          const signInData: SignInData = {
            email: values.email,
            password: values.password,
          };
          const response = await signIn(signInData);
          const accessToken: string = response.accessToken;
          const refreshToken: string = response.refreshToken;
          await login(accessToken, refreshToken);
        }
      } catch (error) {
        if (error instanceof Error) {
          console.error(
            isSignUp ? 'Signup error:' : 'Login error:',
            error.message
          );

          if (error.message === 'Email already in use') {
            setErrors((prevErrors) => ({...prevErrors, email: error.message}));
          } else if (error.message === 'Phone number already in use') {
            setErrors((prevErrors) => ({
              ...prevErrors,
              phoneNumber: error.message,
            }));
          } else {
            setErrors((prevErrors) => ({
              ...prevErrors,
              [isSignUp ? 'signup' : 'login']: error.message,
            }));
          }
        } else {
          toast.error('An unexpected error occurred');
        }
      }
    }
    setIsSubmitting(false);
    setIsLoading(false);
  };

  const renderErrorMessage = (fieldName: string) => {
    if (errors[fieldName]) {
      return (
        <span className="flex items-center gap-2">
          <img src={Info} alt="Error" />
          <span className="text-orangeDark px-4 rounded text-sm font-normal bg-lightOrange">
            {errors[fieldName]}
          </span>
        </span>
      );
    }
    return null;
  };

  return (
    <div className="h-screen w-screen flex justify-between">
      <div className="flex flex-col w-full px-4 xl:px-0 lg:w-2/5 py-6 md:py-5 lg:py-4 2xl:py-20 overflow-y-auto">
        <div className="flex flex-col flex-grow max-w-md lg:ml-[60px] w-full">
          <div className="flex justify-center lg:justify-normal">
            <img
              src={LogoSvg}
              className="w-12 h-12 mb-4 mx-36 lg:mx-0"
              alt="Logo"
            />
          </div>
          <h2 className="text-4xl sm:font-medium lg:font-bold my-0 text-black">
            {isSignUp ? 'Sign Up' : 'Sign In'}
          </h2>
          <p className="text-greyish text-sm sm:font-semibold xl:font-medium mb-4">
            Welcome to Cashia
          </p>
          <form onSubmit={handleFormSubmit} className="flex flex-col gap-2">
            {isSignUp && (
              <>
                <div className="my-1">
                  <label className="text-darkGrey flex gap-2 items-center text-sm font-bold mb-1">
                    First name * {renderErrorMessage('firstName')}
                  </label>
                  <Input
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    className="w-full bg-transparent rounded-lg h-12 focus:ring-2 focus-visible:border-none focus:ring-[#FECED8]"
                  />
                </div>
                <div className="my-1">
                  <label className="text-darkGrey text-sm font-bold mb-1 flex gap-2 items-center">
                    Last name * {renderErrorMessage('lastName')}
                  </label>
                  <Input
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    className="w-full bg-transparent rounded-lg h-12 focus:ring-2 focus-visible:border-none focus:ring-[#FECED8]"
                  />
                </div>
              </>
            )}
            <div className="my-1">
              <label className="text-darkGrey text-sm font-bold mb-1 flex gap-2 items-center">
                Email {isSignUp && <>*</>} {renderErrorMessage('email')}
              </label>
              <Input
                name="email"
                type="email"
                value={values.email}
                onChange={handleChange}
                className="w-full bg-transparent rounded-lg h-12 focus:ring-2 focus-visible:border-none focus:ring-[#FECED8]"
              />
            </div>
            {isSignUp && (
              <div className="my-1">
                <label className="text-darkGrey text-sm font-bold mb-1 flex gap-2 items-center">
                  Phone Number * {renderErrorMessage('phoneNumber')}
                </label>
                <CustomPhoneInput
                  countryCode={values.phoneNumber?.countryCode || ''}
                  phoneNumber={values.phoneNumber?.number || ''}
                  onChange={(countryCode: string, number: string) =>
                    setValues((prevValues) => ({
                      ...prevValues,
                      phoneNumber: {countryCode, number},
                    }))
                  }
                />
              </div>
            )}
            <div>
              <div className="flex items-center mt-2 mb-1 gap-2">
                <p className="text-darkGrey text-sm font-bold font-['Metropolis']">
                  Password {isSignUp && <>*</>}
                </p>
                <p className="flex-1">{renderErrorMessage('password')}</p>
              </div>
              {isSignUp ? (
                <p className="text-xs text-foggy mt-1">
                  Password must be at least 8 characters long, and contain at
                  least one upper and lowercase character, numeric, and special
                  character.
                </p>
              ) : (
                <p className="text-xs text-foggy mt-1">
                  Must have min, 8 characters with upper and lowercase
                  characters
                </p>
              )}
              <div className="w-full flex justify-center items-center bg-transparent rounded-lg h-12 focus:ring-2 focus:ring-[#FECED8] relative">
                <Input
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  type={showPassword ? 'text' : 'password'}
                  className="w-full bg-transparent rounded-lg h-12 focus:ring-2 focus-visible:border-none focus:ring-[#FECED8]"
                />
                <div
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer">
                  {showPassword ? (
                    <img src={Eye} height={16} width={16} />
                  ) : (
                    <img src={EyeSlash} height={16} width={16} />
                  )}
                </div>
              </div>
            </div>
            <Button
              type="submit"
              className="bg-smoothRed text-white w-full h-12 rounded-lg mt-2"
              disabled={isSubmitting}>
              {isLoading ? (
                <Spinner className="fill-white" />
              ) : isSignUp ? (
                'Sign Up'
              ) : (
                'Sign In'
              )}
            </Button>
          </form>
          {!isSignUp && (
            <div className="flex justify-end items-center w-full mt-4">
              <Button
                variant="ghost"
                className="text-smoothRed text-sm font-semibold">
                Forgot password
              </Button>
            </div>
          )}
          {!isSignUp ? (
            <p className="flex items-center justify-center whitespace-nowrap">
              <span className="text-sm text-black sm:font-semibold lg:font-medium">
                Don&apos;t have an account?
              </span>
              <Button
                variant="ghost"
                className="text-smoothRed font-semibold text-sm"
                onClick={toggleAuthMode}>
                {isNavigating ? <Spinner /> : 'Sign Up Here'}
              </Button>
            </p>
          ) : (
            <p className="flex items-center justify-center whitespace-nowrap">
              <span className="text-sm text-black sm:font-semibold lg:font-medium">
                Already have an account?
              </span>
              <Button
                variant="ghost"
                className="text-smoothRed font-semibold text-sm"
                onClick={toggleAuthMode}>
                {isNavigating ? <Spinner /> : 'Sign In Here'}
              </Button>
            </p>
          )}
        </div>
        <div className="text-center px-4 max-w-md lg:ml-[60px] w-full xl:px-0 text-sm">
          <p className="text-black">
            By submitting this form, I agree to the{' '}
            <span
              onClick={() => navigate('/terms')}
              className="text-smoothRed font-bold cursor-pointer whitespace-nowrap">
              Terms & Conditions
            </span>
          </p>
          <p className="mt-1 text-greyBlueish font-normal text-xs">
            <span className="text-greyBlueish">©</span>{' '}
            {new Date().getFullYear()} Cashia Limited All Rights Reserved.
          </p>
        </div>
      </div>
      <div className="hidden lg:flex w-1/2  2xl:mr-5 xl:mb-5 relative my-[12px] overflow-hidden max-w-[85vh] aspect-[85/95] mx-[20px] xl:max-w-[1020px] xl:aspect-[85/95] 2xl:max-w-[1190px] 2xl:aspect-[85/95]">
        <div className="relative w-full h-full aspect-[85/95] mx-auto flex items-center justify-center">
          <img
            src={SignupBgImage}
            className="absolute inset-0 w-full h-full rounded-3xl object-cover object-center"
            alt="Background"
          />
          <div className="absolute inset-x-0 top-0 h-1/4 rounded-3xl bg-gradient-to-b from-black to-transparent opacity-50" />
          <div className="absolute inset-x-0 bottom-0 h-1/2 rounded-3xl bg-gradient-to-t from-black to-transparent opacity-50" />
          <div className="absolute inset-0 flex flex-col justify-between items-center text-white p-8 2xl:p-16">
            <h1 className="text-4xl flex flex-col justify-center py-16 font-semibold">
              Breathe Easy Wacha <br />
              <span className="flex gap-4 p-4 ml-16">
                pesa <span className="text-smoothRed italic"> Iflow</span>
              </span>
            </h1>
            <div className="flex flex-col items-center gap-10">
              <p className="text-lg text-center">
                A sentence describing our business will be placed here to entice
                users on how our services are good for them
              </p>
              <div className="flex mb-6 gap-6">
                <Button className="bg-transparent border border-white rounded-full px-6 py-2 flex items-center">
                  <img src={Lock} className="w-5 h-5 mr-2" alt="Lock icon" />
                  Secure 3DS payment
                </Button>
                <Button className="bg-transparent border border-white rounded-full px-6 py-2 flex items-center">
                  <img src={Send} className="w-5 h-5 mr-2" alt="Send icon" />
                  Shareable Payment link
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthComponent;

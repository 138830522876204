import {Toaster, useMediaQuery} from '@cashiaApp/web-components';
import React, {useEffect} from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';

import RequireAuthLayout from './AuthRequired';
import AuthComponent from '../pages/auth/Signup';
import HomePage from '../pages/home';
import ActivateAccount from '../pages/home/ActivateAccount';
import LinksPage from '../pages/links';
import BusinessInfo from '../pages/selfOnboarding/components/BusinessInfo';
import BusinessOwnerInfo from '../pages/selfOnboarding/components/BusinessOwnerInfo';
import Success from '../pages/selfOnboarding/components/Success';
import BankInfo from '../pages/selfOnboarding/components/bankinfo';
import SocialMedia from '../pages/selfOnboarding/components/socialMedia';
import SelfOnboardingLayout from '../pages/selfOnboarding/layout';
import Terms from '../pages/terms/terms';

type RouteTitles = {
  [key: string]: string;
};

const routeTitles: RouteTitles = {
  '/': 'Cashia',
  '/signup': 'Signup',
  '/login': 'Login to your account',
  '/home': 'Dashboard',
  '/home/self-onboarding': 'Self Onboarding',
  '/home/self-onboarding/business-info': 'Business Information',
  '/home/self-onboarding/business-owner-info': 'Business Owner Details',
  '/home/self-onboarding/bank-info': 'Bank Account Details',
  '/home/self-onboarding/social-media': 'Social Media Details',
  '/home/success': 'Business Profile Complete',
};

const getNestedTitle = (path: string): string => {
  const pathParts = path.split('/').filter(Boolean);
  let currentPath = '';
  let lastValidTitle = 'Cashia';

  for (let i = pathParts.length; i > 0; i--) {
    currentPath = '/' + pathParts.slice(0, i).join('/');
    if (currentPath in routeTitles) {
      lastValidTitle = routeTitles[currentPath] ?? 'Cashia';
      break;
    }
  }

  return lastValidTitle === 'Cashia' ? 'Cashia' : `${lastValidTitle} | Cashia`;
};

const AppRoutes: React.FC = () => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const location = useLocation();

  useEffect(() => {
    document.title = getNestedTitle(location.pathname);
  }, [location]);

  return (
    <>
      <Toaster
        richColors
        toastOptions={{}}
        position={isDesktop ? 'bottom-left' : 'top-right'}
      />
      <Routes>
        <Route path="/terms" element={<Terms />} />
        <Route index element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<AuthComponent />} />
        <Route path="/signup" element={<AuthComponent />} />
        <Route path="/" element={<RequireAuthLayout />}>
          <Route path="home" element={<HomePage />}>
            <Route path="success" element={<Success />} />
            <Route index element={<ActivateAccount />} />
            <Route path="self-onboarding" element={<SelfOnboardingLayout />}>
              <Route path="business-info" element={<BusinessInfo />} />
              <Route path="bank-info" element={<BankInfo />} />
              <Route path="social-media" element={<SocialMedia />} />
              <Route
                path="business-owner-info"
                element={<BusinessOwnerInfo />}
              />
            </Route>
          </Route>
          <Route path="links" element={<LinksPage />} />
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
